<template>
  <div class="world news-overview-page">
    <div class="max-w-7xl mx-auto mt-8 mb-8 px-5 md:mt-2">
      <h1>{{ $t('Het laatste nieuws over Wilms') }}</h1>

      <div v-if="(highlightedNewsItem && hasTrans(highlightedNewsItem)) || subHighlightedNewsItems.length > 0" class="grid grid-cols-2 lg:grid-cols-1 mt-12 gap-10">
        <router-link v-if="highlightedNewsItem && hasTrans(highlightedNewsItem)" :to="{ name: 'ww.news.detail', params: {id: highlightedNewsItem.id} }">
          <img v-if="images[highlightedNewsItem.image_file_id]" :title="getTranslation(highlightedNewsItem).title" class="shadow-2xl rounded-sm" :src="images[highlightedNewsItem.image_file_id].url" :alt="getTranslation(highlightedNewsItem).title"/>
          <h3 class="text-xl text-wilmsBlue uppercase font-700 leading-tight mt-6 mb-4">{{ getTranslation(highlightedNewsItem).title }}</h3>
          <div class="text-wilmsBodyTextGrey pb-4" v-html="getTranslation(highlightedNewsItem).teaser"></div>
          <span class="block font-100 text-wilmsLightBlue font-600">{{ dateTime.fromISO(highlightedNewsItem.created).setLocale(activeLocale).toFormat('dd.LL.yyyy') }}</span>
        </router-link>

        <div v-if="subHighlightedNewsItems.length > 0">
          <router-link :to="{ name: 'ww.news.detail', params: {id: subHighlightNewsItem.id} }" :key="subHighlightNewsItem.id" class="flex subhighlight-news flex-row mb-4" v-for="subHighlightNewsItem in subHighlightedNewsItems">
            <div class="pr-6">
              <h3 class="text-xl md:text-base text-wilmsBlue uppercase font-700 leading-tight pb-1">
                {{ getTranslation(subHighlightNewsItem).title }}
              </h3>
              <div class="text-wilmsBodyTextGrey pb-1 md:text-sm">
                {{ getTranslation(subHighlightNewsItem).teaser }}
              </div>
              <span class="block font-100 text-wilmsLightBlue font-600 md:hidden">{{ dateTime.fromISO(subHighlightNewsItem.created).setLocale(activeLocale).toFormat('dd.LL.yyyy') }}</span>
            </div>
            <div class="ml-auto image">
              <img v-if="images[subHighlightNewsItem.image_file_id]" :alt="getTranslation(subHighlightNewsItem).title" :title="getTranslation(subHighlightNewsItem).title" class="shadow-xl rounded-sm" :src="images[subHighlightNewsItem.image_file_id].url"/>
            </div>
          </router-link>
        </div>
      </div>

      <div class="grid grid-cols-3 md:grid-cols-2 xs:grid-cols-1 md:gap-5 gap-8 mb-10" v-if="isLoading">
        <div>
          <div class="rounded-sm w-full bg-no-repeat bg-center bg-loading-placeholder blog-item-loading-placeholder"></div>
          <div class="mt-2 p-4 w-1/2 bg-loading-placeholder"></div>
        </div>
        <div>
          <div class="rounded-sm w-full bg-no-repeat bg-center bg-loading-placeholder blog-item-loading-placeholder"></div>
          <div class="mt-2 p-4 w-1/2 bg-loading-placeholder"></div>
        </div>
        <div>
          <div class="rounded-sm w-full bg-no-repeat bg-center bg-loading-placeholder blog-item-loading-placeholder"></div>
          <div class="mt-2 p-4 w-1/2 bg-loading-placeholder"></div>
        </div>
      </div>

      <transition-group name="fade" tag="div" class="grid grid-cols-3 md:grid-cols-2 md:gap-5 xs:grid-cols-1 gap-8 mb-10 mt-10" v-if="!isLoading">
        <router-link :to="{ name: 'ww.news.detail', params: {id: post.id} }" v-for="(post, i) in filteredPosts" class="" v-bind:key="'post-' + i">
          <div v-if="imagesLoading" class="rounded-sm w-full bg-no-repeat bg-center bg-loading-placeholder bg-loading-placeholder-news"></div>
          <img v-if="images[post.image_file_id]" :alt="getTranslation(post).title" :title="getTranslation(post).title" class="shadow-2xl rounded-sm" :src="images[post.image_file_id].url"/>
          <h3 class="text-xl md:text-lg text-wilmsBlue uppercase font-700 leading-tight mt-6">{{ getTranslation(post).title }}</h3>
          <div class="text-wilmsBodyTextGrey md:text-sm pt-4 pb-4" v-html="getTranslation(post).teaser"></div>
          <span class="font-100 text-wilmsLightBlue md:text-sm font-600">{{ dateTime.fromISO(post.created).setLocale(activeLocale).toFormat('dd.LL.yyyy') }}</span>
        </router-link>
      </transition-group>

      <nav class="relative z-0 inline-flex-sm mb-10" v-if="!isLoading && filteredPosts.length > 0">
        <a v-for="i in Math.ceil(total / perPage)"
           :key="i"
           v-bind:class="i === page ? 'bg-wilmsBlue text-white' : null"
           v-on:click="nextPage(i - 1)"
           class="text-center font-700 text-lg border rounded-sm border-wilmsBlue p-1 px-3 mr-2 text-wilmsBlue cursor-pointer">
          {{ i }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      isLoading: false,
      imagesLoading: false,
      subHighlightedNewsItems: [],
      highlightedNewsItem: null,
      dateTime: DateTime
    }
  },
  async mounted () {
    await this.$store.dispatch('NewsModule/fetchNews')

    this.highlightedNewsItem = (await ApiService.fetchLatestHighlightedNewsItem()).data
    this.subHighlightedNewsItems = (await ApiService.fetchLatestSubhighlightedNewsItem()).data.filter(post => this.hasTrans(post))

    this.imagesLoading = true
    await this.$store.dispatch('fetchImages', { objects: this.posts })
    await this.$store.dispatch('fetchImages', { objects: this.highlightedNewsItem })
    await this.$store.dispatch('fetchImages', { objects: this.subHighlightedNewsItems })
    this.imagesLoading = false
  },
  methods: {
    async nextPage (i) {
      if (i !== this.page) {
        await this.$store.dispatch('NewsModule/fetchNews', { page: i })
        await this.$store.dispatch('fetchImages', { objects: this.posts })
      }
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    },
    total: function () {
      return this.$store.state.NewsModule.total
    },
    page () {
      return this.$store.state.NewsModule.page
    },
    perPage () {
      return this.$store.state.NewsModule.perPage
    },
    posts () {
      return this.$store.getters['NewsModule/getNews']
    },
    filteredPosts () {
      return this.posts.filter(post => this.hasTrans(post))
    },
    pageTotal () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.total) {
        offsetMax = this.total
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
